.hidden {
    display: none !important;
}
.formRow {
    padding:10px;
    max-width: 100%;
    display: flex;
    gap: 20px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #fff;
}
.item-photo {
    padding:10px 0;
}

.flex{
    display: flex;
}
.marginNormal{
    margin-top: 16px;
    margin-bottom: 8px;
}
.g10{
    gap: 10px;
}
.g20{
    gap: 20px;
}
.grid{
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 20px;
}
.column{
    flex-direction: column;
}
.editor{
    width: 70%;
}
body {
    min-width: 400px;
}
.image-block{
    border: 1px solid #000;
}
.item-oneimg-product-item{
    max-width: 150px;
    max-height: 150px;
}
.image img {
    width: 100%;
}
.item-oneimg-product-item .info {
    font-size: 12px;
}